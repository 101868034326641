<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col>
                      <v-select filled v-model="filters.services"
                                color="grey"
                                :items="services"
                                label="Послуги"
                                multiple
                                hide-details/>
                    </v-col>
                    <v-col cols="12">
                      <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect v-model="filters.city"
                                            label="Населений пункт" filled
                                            select_type="city"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm;"
         :style="`zoom: ${scale / 100}`">
      <div class="report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table style="width: 100%;">
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Відомості по договорах)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead id="header">
            <tr>
              <th rowspan="2" style="min-width: 200px;">Вид житла</th>
              <th rowspan="2" style="min-width: 76px; width: 76px">Абонентів</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Зареєстр.(чол.)</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Абоненти без договору</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Договорів на поч. періоду</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Нові договора</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Закриті договора</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Договорів на <br> кін. періоду</th>
            </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td style="min-width: 76px; width: 76px">{{ item.level > 0 ? item.flats : '' }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.level > 0 ? item.registered : '' }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.level > 0 ? item.contract_without : ''}}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_start }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_new }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_close }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_end }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td style="min-width: 200px;">{{ buildingTypes[subitem.building_type] }}</td>
              <td style="min-width: 76px; width: 76px">{{ item.flats }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.registered }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_without }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_start }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_new }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_close }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.contract_end }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td style="min-width: 200px;">{{ buildingTypes[subitem.building_type] }}</td>
              <td style="min-width: 76px; width: 76px">{{ subitem.flats }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.registered }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.contract_without }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.contract_start }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.contract_new }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.contract_close }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.contract_end }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0" style="text-align: center">
            <td class="grow" colspan="4" style="font-weight: 500; text-align: right;">Всього: </td>
            <td style="min-width: 80px; width: 80px">{{ getTotal('contract_start') | formatNumber }}</td>
            <td style="min-width: 80px; width: 80px">{{ getTotal('contract_new') | formatNumber }}</td>
            <td style="min-width: 80px; width: 80px">{{ getTotal('contract_close') | formatNumber }}</td>
            <td style="min-width: 80px; width: 80px">{{ getTotal('contract_end') | formatNumber }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate, formatNumber} from "@/filters";
import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {
  getCurrentDate,
  getPrintDateRange,
  isExpanded,
  PORTRAIT_ZOOM_WIDTH
} from "@/utils/reports";
import {getFlatType, buildingTypes} from "@/utils/icons"
import {mapGetters} from "vuex";


export default {
  name: "contract_stats_new",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'open_all', 'generate_xlsx', 'collapse_button', 'expand_button', 'generate_email', 'email_address'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker"),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll'
    }),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      buildingTypes,
      showExpand: false,
      x: 0,
      y: 0,
      expand: null,
      zoom: 1,
      pages: 0,
      pages_data: {},
      filters: {
        services: [],
        not_living: null,
        city: null,
        checker_id: null
      },
      split_height: 0,
      zoom_width: PORTRAIT_ZOOM_WIDTH,
      services_columns: {},
      max_lvl: 0,
      by_grouping: false,
      items: [],
      cols: [],
      grouping: [
        { text: 'Житлові/нежитлові приміщення', value: 'not_living', on: true },
        { text: 'Послуга', value: 'service_id', on: true },
        { text: 'Вид житла', value: 'building_type', on: false },
      ],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      open_all_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
    }
  },
  methods: {
    getFlatType,
    onCheckerChange(payload) {
      this.filters.checker_id = payload.value || null
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    formatNumber,
    getCurrentDate,
    getPrintDateRange,
    isExpanded,
    toggleExpand(level) {
      let lvl = 0
      lvl = level === -1
          ? this.expand ? this.max_lvl : 0
          : level

      const arr = this.expand ? [...Array(lvl+1).keys()] : [...Array(this.max_lvl+1).keys()].filter(i => i >= lvl).reverse()
      arr.forEach(el => {
        this.toggleExpand_one(el)
      })
    },
    toggleExpand_one(level) {
      const selector = `[data-level="level-${level}"]`
      document.querySelectorAll(selector).forEach(el => {
        const el_class = el.className
        const button_id = el.dataset.button
        if (el_class.indexOf('details') === -1) {
          const btn = document.getElementById(button_id)
          const close = btn.classList.contains('mdi-plus')

          if (this.expand) {
            if (close) {
              this.showRows(el_class, button_id)
            }
          } else {
            if (!close) {
              this.showRows(el_class, button_id)
            }
          }
        }
      })
    },
    pushUp(payload) {
      let current_index = this.grouping.indexOf(payload)
      // let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === 0) {
        new_arr = [...this.grouping.slice(1).concat([payload])]
      }
      //
      // if (current_index === 1) {
      //   new_arr = [...this.grouping.slice(1).concat(this.grouping.slice(0,1))]
      // }

      if (current_index >= 1) {
        new_arr = [
            ...this.grouping.slice(0, current_index - 1).concat([payload])
            .concat(this.grouping.slice(current_index-1, current_index))
            .concat(this.grouping.slice(current_index+1))
        ]
      }
      this.grouping = [...new_arr]
    },
    pushDown(payload) {
      let current_index = this.grouping.indexOf(payload)
      let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === arr_length) {
        new_arr = [...[payload].concat(this.grouping.slice(0, current_index))]
      } else {
        new_arr = [
            ...this.grouping.slice(0, current_index)
            .concat(this.grouping.slice(current_index + 1, current_index + 2))
            .concat(this.grouping.slice(current_index, current_index + 1))
            .concat(this.grouping.slice(current_index + 2))
        ]
      }

      this.grouping = [...new_arr]
    },
    getCollapseIcon(level) {
      return level < this.max_lvl - 1 ? 'mdi-minus' : 'mdi-plus'
    },
    showRows(group_class, button_id) {
      const button = document.getElementById(button_id)
      const close = button.classList.contains('mdi-plus')
      const groups = document.getElementsByClassName(group_class)
      const group_split = group_class.split(' ')
      let group_without = []

      Array.from(groups).forEach(el => {
        const cls_name = el.className.split(' ')
        if (close) {
          if (cls_name.length === group_split.length + 1) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        } else {
          if (el.className.trim() !== group_class.trim()) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        }
      })

      if (close) {
        button.classList.remove('mdi-plus')
        button.classList.add('mdi-minus')
        group_without.forEach(el => {
          el.style.display = ''
        })
      } else {
        button.classList.remove('mdi-minus')
        button.classList.add('mdi-plus')
        group_without.forEach(el => {
          el.style.display = 'none'
          Array.from(el.getElementsByTagName('button')).forEach(btn => {
            btn.classList.remove('mdi-minus')
            btn.classList.add('mdi-plus')
          })
        })
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.contract_stats_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `contract_stats_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping}),
          email: this.email_address
        }
        reportAPI.contract_stats_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.contract_stats(payload)
            .then(response => response.data)
            .then(data => {
              let max_lvl = 0
              let by_grouping = false
              data.forEach(row => {
                if (row.level >= max_lvl) {
                  max_lvl = row.level
                  if (row['items']) {
                    by_grouping = true
                  }
                }
              })
              this.by_grouping = by_grouping
              this.max_lvl = max_lvl
              this.items = data
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl -1)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = PORTRAIT_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * PORTRAIT_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.settings_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_open_all() {
      this.open_all_watcher = this.$watch(
          'open_all',
          {
            handler(payload) {
              if (payload !== undefined) {
                this.showExpand = false
                const el = payload ? this.expand_button.$el : this.collapse_button.$el
                const cord = el.getBoundingClientRect()
                this.expand = payload
                this.x = cord.left
                this.y = cord.bottom + 2
                this.$nextTick(() => {
                  this.showExpand = true
                })
                this.$emit('clearOpenAll')
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_generate_xlsx()
    this.watch_open_all()
    this.watch_generate_email()

    reportAPI.report_settings("contract_stats")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.open_all_watcher) {
      this.open_all_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #cbcbcb;

table, th, td {
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}

thead {
  th {
    padding: 8px !important;
  }
}

th {
  background: #f3f3f3;
  font-weight: 500 !important;
}
th, td {
  padding: 5px;
  font-size: 10.6px;
  font-weight: 400;
  width: auto;
}
th.grow, td.grow {
  width: 180px;
  min-width: 180px;
}
tbody {
  tr {
    td {
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        padding-left: 14px;
      }
    }
  }
}
:deep(td button:focus) {
  background: none !important;
}
.bg-level-0 {
  background: #e2e2e2;
}
.bg-level-1 {
  background: #f4f4f4;
}
.bg-level-2 {
  background: #f6f6f6;
}
.bg-level-3 {
  background: #f8f8f8;
}

.page-a4-landscape-auto {
  width: 1160px !important;
  position: relative;
}


@media print {
  * {
    float: none !important;
    clear: both;
  }

  .pages {
    height: auto !important;
    overflow: visible !important;
  }

  .page {
    visibility: visible !important;
    margin: 0 2px !important;
  }

  @page {
    margin: 0 0 5mm 0 !important;
  }
}

</style>